// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../../../../node_modules/vue-loader/dist/stylePostLoader.js!../../../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../../../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.box[data-v-2dbd95b5]{position:relative}.title[data-v-2dbd95b5]{display:flex}.title .state[data-v-2dbd95b5]{font-size:16px}.remove[data-v-2dbd95b5]{position:absolute;top:10px;right:10px;padding:0}.bootOrder[data-v-2dbd95b5]{display:flex;align-items:center}.buttons[data-v-2dbd95b5]{width:100%;display:flex;justify-content:flex-end}.volume-status[data-v-2dbd95b5]:first-letter{text-transform:uppercase}.resource-external[data-v-2dbd95b5]{display:flex;justify-content:center;align-items:center}.banner[data-v-2dbd95b5]{margin:10px 0}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
